<template>
  <div class="p-content column-center" style="padding: 100px 0">
    <div class="p-panel" style="width: 800px">
      <div class="p-title" style="margin-bottom: 20px">报名确认</div>
      <div class="column enroll-tip-panel" style="align-items: center; margin: 30px 0">
        <div>您已完成报名表，请进入</div>
        <div style="color: #ffb800; font-weight: bold; font-size: 24px">
          第二步
        </div>
        <div>点击下方支付资料费</div>
      </div>
      <a-button type="primary" style="margin-bottom: 20px" @click="goPay">支付资料费</a-button>
      <div class="enroll-tip">
        点击进入有赞页面，请用微信扫描二维码，在手机端购买“300元资料费”虚拟商品，以完成费用缴纳
      </div>
      <!-- <div
        class="column enroll-tip-panel"
        style="align-items: center; margin: 30px 0"
      >
        <div style="color: #ffb800; font-weight: bold; font-size: 24px">
          第三步
        </div>
        <div style="text-align: center">
          请将有赞“支付资料费”的订单号和手机号，填入下表，以供确认您的缴费信息。
        </div>
      </div>
      <a-row>
        <a-col :span="24">
          <a-form-model
            ref="yzForm"
            :rules="yzRules"
            :model="yzForm"
            layout="vertical"
          >
            <a-form-model-item
              has-feedback
              label="有赞订单号"
              prop="yz_order_num"
            >
              <a-input v-model="yzForm.yz_order_num" style="width: 355px" />
            </a-form-model-item>
            <a-form-model-item has-feedback label="有赞手机号" prop="yz_phone">
              <a-input v-model="yzForm.yz_phone" style="width: 355px" />
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <a-button
        :loading="yzBtnState.loading"
        type="primary"
        style="margin-top: 30px"
        @click="onYzSubmit"
      >
        提交订单信息
      </a-button>
      <div
        class="column enroll-tip-panel"
        style="align-items: center; margin: 30px 0"
      >
        <div style="color: #ffb800; font-weight: bold; font-size: 24px">
          上传作品，非必选项
        </div>
        <div style="text-align: center">
          （无需马上提交作品，可在作品创作完成后再次登录上传，也可以忽略本项）
        </div>
      </div>
      <a-row style="margin-top: 30px">
        <a-col :span="24">
          <a-form-model
            ref="opusForm"
            :rules="opusRules"
            :model="opusForm"
            layout="vertical"
          >
            <a-form-model-item label="上传作品" prop="opus_img_url">
              <a-spin
                :spinning="imageLoading"
                style="width: 128px; height: 128px"
              >
                <a-upload
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :before-upload="imageBeforeUpload"
                >
                  <div
                    v-if="opusForm.opus_img_url"
                    class="div-background"
                    :style="{
                      backgroundImage: 'url(' + opusForm.opus_img_url + ')',
                    }"
                  ></div>
                  <div v-else>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      {{ imageLoading ? "上传中" : "请上传" }}
                    </div>
                  </div>
                </a-upload>
              </a-spin>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <a-button
        :loading="opusBtnState.loading"
        type="primary"
        style="margin-top: 30px"
        @click="onOpusSubmit"
      >
        提交作品
      </a-button> -->
    </div>
  </div>
</template>

<script>
import * as cos from "../../utils/cosHelper";
import { mapActions, mapState, mapGetters } from "vuex";
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,
      youzan_url:
        "https://shop98326360.youzan.com/v2/showcase/homepage?alias=OMnDZJ6DTx&dc_ps=2874718250585993216.300001",
      yzBtnState: {
        loading: false,
      },
      opusBtnState: {
        loading: false,
      },
      imageLoading: false,

      yzForm: {
        yz_phone: "",
        yz_order_num: "",
      },
      opusForm: {
        opus_img_url: null,
        opus_width: 0,
        opus_height: 0,
      },

      yzRules: {
        // yz_phone: [
        //   {
        //     required: true,
        //     message: "请输入手机号",
        //     trigger: "blur",
        //   },
        // ],
        // yz_order_num: [
        //   {
        //     required: true,
        //     message: "请输入订单号",
        //     trigger: "blur",
        //   },
        // ],
        // opus_img_url: [
        //   {
        //     required: true,
        //     message: "请上传作品",
        //     trigger: "blur",
        //   },
        // ],
      },
      opusRules: {},
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getOpus();
    this.getYz();
  },
  methods: {
    ...mapActions({
      userGetYzAction: "userGetYz",
      userGetOpusAction: "userGetOpus",
      userUpdateYzAction: "userUpdateYz",
      userUpdateOpusAction: "userUpdateOpus",
    }),
    imageBeforeUpload(file) {
      this.imageLoading = true;
      // 上传
      cos
        .upload(file, "opus")
        .then((res) => {
          this.opusForm.opus_img_url = res.fullPath;

          var img_url = res.fullPath;
          var img = new Image();
          img.src = img_url;
          img.onload = () => {
            this.opusForm.opus_width = img.width;
            this.opusForm.opus_height = img.height;
          };
        })
        .catch((error) => {
          this.$message.error("上传失败");
        })
        .finally(() => {
          this.imageLoading = false;
        });
      return false;
    },
    onOpusSubmit() {
      this.opusBtnState.loading = true;
      this.$refs.opusForm.validate((valid) => {
        if (valid) {
          this.userUpdateOpusAction(this.opusForm)
            .then((res) => {
              this.$message.success("报名成功");
            })
            .catch((error) => {
              this.$message.error(error);
            })
            .finally(() => {
              this.opusBtnState.loading = false;
            });
        } else {
          this.opusBtnState.loading = false;
          return false;
        }
      });
    },
    onYzSubmit() {
      this.yzBtnState.loading = true;
      this.$refs.yzForm.validate((valid) => {
        if (valid) {
          this.userUpdateYzAction(this.yzForm)
            .then((res) => {
              this.$message.success("报名成功");
            })
            .catch((error) => {
              console.error(error);
              this.$message.error(error);
            })
            .finally(() => {
              this.yzBtnState.loading = false;
            });
        } else {
          this.yzBtnState.loading = false;
          return false;
        }
      });
    },
    goPay() {
      window.location.href = this.youzan_url;
    },
    getOpus() {
      this.userGetOpusAction()
        .then((res) => {
          this.opusForm.opus_img_url = res.opus_img_url;
          this.opusForm.opus_width = res.opus_width;
          this.opusForm.opus_height = res.opus_height;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getYz() {
      this.userGetYzAction()
        .then((res) => {
          this.yzForm.yz_phone = res.yz_phone;
          this.yzForm.yz_order_num = res.yz_order_num;
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.enroll-tip {
  font-size: 12px;
  margin-bottom: 20px;
}
.enroll-tip-panel {
  padding: 30px 60px;
  border-radius: 30px;
  background-color: #00a7ee;
  color: white;
  font-weight: bold;
}
</style>